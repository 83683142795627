import { isHttpError } from '@api/types';
import { DEFAULT_REQUEST_STALE_TIME } from '@commons/consts';
import { QueryClient } from '@tanstack/react-query';

const shouldRetry = (failureCount: number, error: Error) =>
  isHttpError(error) && error.getErrorCode() >= 500 && failureCount < 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => shouldRetry(failureCount, error as Error),
      staleTime: DEFAULT_REQUEST_STALE_TIME,
    },
  },
});
