import { fetchTokens } from '@api/auth/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { routes } from '@routers/routes';
import { Loader } from '@fortum/elemental-ui';
import { getPathFromState, shouldRetryAuth } from '@utils/auth';
import { StorageUtils } from '@utils/storage';
import { SessionStorageKeys } from '@commons/consts';
import { GeneralError } from '@pages/GeneralError/GeneralError';
import { logException } from '@configs/azureAppInsights/utils';
import { PageContainer } from './styles';
import { logCiamRedirectErrors } from './utils';

export const Authorization: React.FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  const handleError = useCallback((redirectPath: string | undefined) => {
    const shouldRetry = shouldRetryAuth();

    if (!shouldRetry) {
      setError(true);
      return;
    }

    navigate(redirectPath ?? routes.index);
  }, []);

  const handleRedirectError = useCallback(
    (
      savedState: string | null,
      state: string | null,
      code: string | null,
      codeVerifier: string | null,
      redirectPath: string | undefined,
    ) => {
      logCiamRedirectErrors(savedState, state, code, codeVerifier);

      handleError(redirectPath);
    },
    [],
  );

  const handleTokenFetchingError = useCallback(
    (e: Error, redirectPath: string | undefined) => {
      logException(`Error while fetching token. ${e}`);
      handleError(redirectPath);
    },
    [],
  );

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    const codeVerifier = StorageUtils.popItem(SessionStorageKeys.codeVerifier);
    const savedState = StorageUtils.popItem(SessionStorageKeys.state);
    const redirectPath = state ? getPathFromState(state) : undefined;

    if (savedState !== state || !code || !codeVerifier) {
      handleRedirectError(savedState, state, code, codeVerifier, redirectPath);
    } else {
      fetchTokens(code, codeVerifier)
        .then(response => {
          sessionStorage.setItem(
            SessionStorageKeys.token,
            response.access_token,
          );
          sessionStorage.setItem(SessionStorageKeys.idToken, response.id_token);
          navigate(redirectPath ?? routes.index);
        })
        .catch(e => {
          handleTokenFetchingError(e, redirectPath);
        });
    }
  }, [searchParams.get('code'), searchParams.get('state')]);

  return error ? (
    <GeneralError />
  ) : (
    <PageContainer>
      <Loader />
    </PageContainer>
  );
};
