import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Authorization } from '@pages/notAuthenticated';
import { lazy } from 'react';
import App from '../App';
import { NotFound } from '../pages';
import { routes, subRoutes } from './routes';

const Consumption = lazy(() => import('@pages/authenticated/Consumption'));
const DeliveryPoints = lazy(
  () => import('@pages/authenticated/DeliveryPoints'),
);
const Invoices = lazy(() => import('@pages/authenticated/Invoices'));
const Reports = lazy(() => import('@pages/authenticated/Reports'));
const TabsOwnInformation = lazy(
  () => import('@pages/authenticated/OwnInformation/TabsOwnInformation'),
);
const ContactUs = lazy(() => import('@pages/authenticated/ContactUs'));

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.index} element={<App />}>
          <Route path={subRoutes.consumption} element={<Consumption />} />
          <Route
            path={subRoutes.consumptionSites}
            element={<DeliveryPoints />}
          />
          <Route path={subRoutes.invoices} element={<Invoices />} />
          <Route path={subRoutes.reports} element={<Reports />} />
          <Route
            path={subRoutes.ownInformation}
            element={<TabsOwnInformation />}
          />
          <Route path={subRoutes.contactUs} element={<ContactUs />} />
        </Route>
        <Route path={routes.authorization} element={<Authorization />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
