import { FULL_DATE_FORMAT } from '@commons/consts';
import { Namespace } from '@configs/i18n';
import jsPDF from 'jspdf';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import pdfLogo from '../assets/logo_Pdf.png';
import { MultiDeliveryPoints } from './hooks/useForcecastPdfDownloader';

// Function to add data to the pdfument
export const addMultiDeliveryPoints = (
  pdf: jsPDF,
  data: MultiDeliveryPoints[],
  fn: (pd: jsPDF) => void,
  t: TFunction<Namespace, undefined>,
) => {
  const addHeaderOnPage = () => {
    pdf.addPage();
    fn(pdf); // Call the function that draws the header content (like the image/logo)
    pdf.setFont('FortumSansTT-Regular', 'normal');
    pdf.setFontSize(12);
  };

  // Initial page setup with the header
  addHeaderOnPage();

  // Set initial y position
  let yPos = 55;

  // Utility function to split text into lines if too long
  const splitText = (text: string, maxLength: number): string[] => {
    if (text.length <= maxLength) {
      return [text];
    }
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';
    words.forEach(word => {
      if ((currentLine + word).length <= maxLength) {
        currentLine += `${word} `;
      } else {
        lines.push(currentLine.trim());
        currentLine = `${word} `;
      }
    });
    lines.push(currentLine.trim());
    return lines;
  };

  // Add table headers
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Bold', 'bold');
  pdf.text(t('reportTabs.pdf.infoCompanies'), 10, yPos - 13);

  const headers = [
    { text: t('reportTabs.pdf.companyName'), x: 30 },
    { text: t('reportTabs.pdf.deliveryPointName'), x: 98 },
    { text: t('reportTabs.pdf.deliveryPointAddress'), x: 158 },
    { text: t('reportTabs.pdf.deliveryPointID'), x: 218 },
    // { text: t('reportTabs.pdf.customerNumber'), x: 235 },
  ];

  headers.forEach(header => {
    const lines = splitText(header.text, 20); // Adjust maxLength as necessary
    lines.forEach((line, index) => {
      pdf.text(line, header.x, yPos + index * 5);
    });
  });

  // Increase y position based on the number of lines in the longest header
  const maxLines = Math.max(
    ...headers.map(header => splitText(header.text, 20).length),
  );
  yPos += maxLines * 5 + 7;

  // Iterate over the data and add company names and addresses
  data.forEach((company, companyIndex) => {
    // Avoid adding an extra page if the last company renders on the last page
    if (companyIndex > 0 && yPos > 180) {
      addHeaderOnPage();
      yPos = 55;
    }

    yPos += 5;
    fn(pdf);
    pdf.setFontSize(12);
    pdf.setFont('FortumSansTT-Regular', 'normal');
    // Split company name if it's too long
    const companyNameLines = splitText(company.companyName, 35);

    // Render each line of the company name
    companyNameLines.forEach((line, index) => {
      pdf.text(line, 30, yPos + index * 5);
    });

    // Increase yPos based on the number of lines in the company name
    yPos += companyNameLines.length * 5 + 5;

    // Add addresses
    company.children.forEach(child => {
      const {
        deliveryPointName,
        deliveryPointAddress,
        deliveryPointNumber,
        //    customerNumber,
      } = child;
      pdf.text(deliveryPointName, 100, yPos);
      pdf.text(deliveryPointAddress, 160, yPos);
      pdf.text(deliveryPointNumber, 220, yPos);
      // pdf.text(customerNumber, 235, yPos);
      yPos += 7;

      // Check if new page needed before moving to the next item
      if (yPos > 180) {
        addHeaderOnPage(); // Add a new page with header
        yPos = 40; // Reset yPos for the new page
      }
    });
  });

  // If the last page is blank, remove it
  const totalPages = (pdf as any).internal.getNumberOfPages();
  pdf.setPage(totalPages);
  if (yPos === 55) {
    pdf.deletePage(totalPages); // Remove the last blank page
  }
};

export const pages = (pdf: jsPDF) => {
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Regular', 'normal');
  const totalPages = (pdf as any).internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i += 1) {
    pdf.setPage(i);
    pdf.text(`${i}/${totalPages}`, 270, pdf.internal.pageSize.getHeight() - 10);
  }
};

// Utility function to fetch and convert image to base64
const fetchBase64Image = (url: string): Promise<string> => {
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

let cachedLogoBase64: string | null = null;

// Preload the logo and cache it as base64
const preloadLogo = async () => {
  if (cachedLogoBase64) return; // If already cached, do nothing
  cachedLogoBase64 = await fetchBase64Image(pdfLogo); // Load and convert the image to base64
};

// Call this function early in the application lifecycle
preloadLogo();

export const addHeader = (pdf: jsPDF) => {
  // Ensure the logo is cached
  if (cachedLogoBase64) {
    const logoWidth = 0;
    const logoHeight = 20;

    // Use the cached base64 image directly
    pdf.addImage(cachedLogoBase64, 'PNG', 5, 5, logoWidth, logoHeight);
  }
};

export const customerInfo = (
  pdf: jsPDF,
  t: TFunction<Namespace, undefined>,
  user: string,
  data: MultiDeliveryPoints[],
) => {
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Regular', 'normal');

  pdf.text(`${t('reportTabs.pdf.creationDate')}`, 210, 35);
  pdf.text(`${t('reportTabs.pdf.deliveryPointName')}`, 10, 44);
  pdf.text(`${t('reportTabs.pdf.deliveryPointAddress')}`, 10, 50);
  pdf.text(`${t('reportTabs.pdf.deliveryPointID')}`, 10, 56);

  pdf.text(moment().format(FULL_DATE_FORMAT), 243, 35);

  if (data.length === 1) {
    // Only one company
    pdf.text(data[0].companyName, 10, 35);
    // pdf.text(data[0].children[0].customerNumber, 21, 56);
    if (data[0].children.length === 1) {
      // Only one child
      pdf.text(data[0].children[0].deliveryPointName, 78, 44);
      pdf.text(data[0].children[0].deliveryPointAddress, 78, 50);
      pdf.text(data[0].children[0].deliveryPointNumber, 78, 56);
    } else {
      // Multiple children
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 44);
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 50);
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 56);
    }
  } else {
    // Multiple companies
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 44);
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 50);
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 56);
  }
};

// Table creation in PDF

const HEADER_COLOR = '#0f7a5a';
const PADDING_X = 2;
const UNIT_MARGIN = 5;

export const drawTableHeader = (
  pdf: jsPDF,
  columns: Array<{ title: string; key: string; unit: string }>,
  currentY: number,
  columnWidth: number,
  maxHeaderHeight: number,
) => {
  pdf.setTextColor(HEADER_COLOR);
  pdf.setFont('FortumSansTT-Bold', 'bold');

  columns.forEach((col, index) => {
    const colX = 10 + index * columnWidth;
    const titleLines = pdf.splitTextToSize(
      col.title.toString(),
      columnWidth - PADDING_X * 2,
    );

    titleLines.forEach((line: string, lineIndex: number) => {
      pdf.text(line, colX + PADDING_X, currentY + lineIndex * 5);
    });

    pdf.setDrawColor('#f2f2f2');
    pdf.rect(colX, currentY - 5, columnWidth, maxHeaderHeight + 15);

    if (col.unit) {
      pdf.setFontSize(8);
      pdf.text(
        col.unit.toString(),
        colX + PADDING_X,
        currentY + maxHeaderHeight + UNIT_MARGIN + 3,
      );
      pdf.setFontSize(10);
    }
  });

  return currentY + 6;
};

export const createMonthlyTable = (
  pdf: jsPDF,
  data: any[],
  columns: { title: string; key: string; unit: string }[],
  columnWidth: number,
  startY: number,
  pageHeight: number,
  marginBottom: number,
  rowHeightTable: number,
  HEADER_MARGIN: number,
  t: (key: string) => string,
  customerType: string,
) => {
  const INK_GREY_RGB = 51;
  const BORDER_COLOR = '#f2f2f2';
  let currentY = startY;
  let maxHeaderHeight = 0;

  pdf.setFont('FortumSansTT-Bold', 'bold');
  pdf.setFontSize(10);

  columns.forEach(col => {
    const titleLines = pdf.splitTextToSize(
      col.title.toString(),
      columnWidth - PADDING_X * 2,
    );
    const headerHeight = titleLines.length * 2 + 5;
    maxHeaderHeight = Math.max(maxHeaderHeight, headerHeight);
  });

  currentY = drawTableHeader(
    pdf,
    columns,
    currentY,
    columnWidth,
    maxHeaderHeight,
  );

  currentY += maxHeaderHeight + HEADER_MARGIN;

  pdf.setTextColor(INK_GREY_RGB);
  pdf.setFont('FortumSansTT-Regular', 'normal');

  data.forEach((row, rowIndex) => {
    if (currentY + rowHeightTable > pageHeight - marginBottom) {
      pdf.addPage();
      currentY = 20;

      currentY = drawTableHeader(
        pdf,
        columns,
        currentY,
        columnWidth,
        maxHeaderHeight,
      );
      currentY += maxHeaderHeight + HEADER_MARGIN;
      pdf.setTextColor(INK_GREY_RGB);
      pdf.setFont('FortumSansTT-Regular', 'normal');
    }

    if (rowIndex === data.length - 1) {
      pdf.setFont('FortumSansTT-Bold', 'bold');
    } else {
      pdf.setFont('FortumSansTT-Regular', 'normal');
    }

    columns.forEach((col, index) => {
      const text = row[col.key] || '';
      const colX = 10 + index * columnWidth;

      pdf.text(text.toString(), colX + PADDING_X, currentY);

      pdf.setDrawColor(BORDER_COLOR);
      pdf.rect(
        colX,
        currentY - rowHeightTable + 4,
        columnWidth,
        rowHeightTable,
      );
    });

    currentY += rowHeightTable;
  });
  pdf.setFont('FortumSansTT-Regular', 'normal');
  pdf.text(t('reportTabs.forecastUsageYearly.subText'), 10, currentY);
  const text =
    customerType === 'B2B'
      ? t('reportTabs.forecastUsageYearly.subTextB2B')
      : t('reportTabs.forecastUsageYearly.subTextB2C');
  pdf.text(text, 10, currentY + 5);
};

const renderChange = (
  missingDeliveryPoints: string[],
  change?: string | number,
) => {
  if (missingDeliveryPoints.length > 0) {
    return '( -* )';
  }
  return change ? `(${change}*)` : '';
};

export const createYearlyTable = (
  pdf: jsPDF,
  data: any[],
  columns: { title: string; key: string; unit: string }[],
  columnWidth: number,
  startY: number,
  pageHeight: number,
  marginBottom: number,
  rowHeightTable: number,
  HEADER_MARGIN: number,
  t: (key: string) => string,
  missingDeliveryPoints: string[],
  yearText: string,
) => {
  const INK_GREY_RGB = 51;
  const BORDER_COLOR = '#f2f2f2';
  const PADDING = 4; // Padding for cell content
  let currentY = startY;
  let maxHeaderHeight = 0;

  pdf.setFont('FortumSansTT-Bold', 'bold');
  pdf.setFontSize(10);

  // Calculate max header height
  columns.forEach(col => {
    const titleLines = pdf.splitTextToSize(
      col.title.toString(),
      columnWidth - PADDING * 2,
    );
    const headerHeight = titleLines.length * 1 + 5;
    maxHeaderHeight = Math.max(maxHeaderHeight, headerHeight);
  });

  // Draw table header
  currentY = drawTableHeader(
    pdf,
    columns,
    currentY,
    columnWidth,
    maxHeaderHeight,
  );
  currentY += maxHeaderHeight + HEADER_MARGIN;

  pdf.setTextColor(INK_GREY_RGB);
  pdf.setFont('FortumSansTT-Regular', 'normal');

  // Iterate through data rows
  data.forEach((row, rowIndex) => {
    if (currentY + rowHeightTable > pageHeight - marginBottom) {
      pdf.addPage();
      currentY = 20;

      // Draw table header on new page
      currentY = drawTableHeader(
        pdf,
        columns,
        currentY,
        columnWidth,
        maxHeaderHeight,
      );
      currentY += maxHeaderHeight + HEADER_MARGIN;
      pdf.setTextColor(INK_GREY_RGB);
      pdf.setFont('FortumSansTT-Regular', 'normal');
    }

    // Set bold font for the first row (e.g., "Yhteensä" row)
    if (rowIndex === 0) {
      pdf.setFont('FortumSansTT-Bold', 'bold');
    } else {
      pdf.setFont('FortumSansTT-Regular', 'normal');
    }

    // Calculate maximum row height to accommodate wrapped text
    let maxRowHeight = rowHeightTable;
    const lineHeight = pdf.getFontSize() / pdf.internal.scaleFactor; // Calculate line height based on font size and scale

    // Iterate through columns and fill in data
    columns.forEach((col, index) => {
      let text;
      let wrappedText = [];
      let changeText = '';

      // Handle the first column (row name) with word wrap
      if (index === 0) {
        text = row.name || ' '; // Ensure name is displayed in the first column
        wrappedText = pdf.splitTextToSize(text, columnWidth - PADDING_X * 2); // Split the text for word wrapping
      } else {
        const colData = row[col.key] || {}; // Safe access to column data
        text = colData.value !== undefined ? colData.value.toString() : ' ';

        // Use renderChange only if change data exists
        changeText =
          colData.change !== undefined
            ? renderChange(missingDeliveryPoints, colData.change)
            : '';

        // Split the main text if necessary
        wrappedText = pdf.splitTextToSize(text, columnWidth - PADDING_X * 2);
      }

      // Calculate row height: considering both wrapped text and changeText
      let rowHeightForThisCell = wrappedText.length * lineHeight;

      // Add extra height for the change text, if available
      if (changeText) {
        rowHeightForThisCell += lineHeight;
      }

      // Set a minimum row height if the content is short to avoid misalignment
      if (rowHeightForThisCell < lineHeight + 25) {
        // 10 is padding
        rowHeightForThisCell = lineHeight + 21;
      }

      // Adjust max row height to ensure consistency across the row
      maxRowHeight = Math.max(maxRowHeight, rowHeightForThisCell);

      // Render the text (both for the first column and other columns)
      wrappedText.forEach((line: string | string[], lineIndex: number) => {
        pdf.text(
          line,
          10 + index * columnWidth + PADDING_X,
          currentY + lineIndex * lineHeight,
        );
      });

      // Render changeText below the main text if available
      if (changeText) {
        const yPositionForChange = currentY + wrappedText.length * lineHeight;
        pdf.text(
          changeText,
          10 + index * columnWidth + PADDING_X,
          yPositionForChange + lineHeight,
        );
      }

      const colX = 10 + index * columnWidth;

      // Draw cell border
      pdf.setDrawColor(BORDER_COLOR);
      pdf.rect(colX, currentY - rowHeightTable + 4, columnWidth, maxRowHeight);
    });

    currentY += maxRowHeight; // Update currentY based on the tallest cell in the row
  });
  pdf.setFont('FortumSansTT-Regular', 'normal');
  pdf.text(yearText, 10, currentY);
};
