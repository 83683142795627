import { useDeliveryPoints } from '@api/deliveryPoints';
import { LocalStorageKeys } from '@commons/consts';
import { DataStatus } from '@commons/types/dataStatus';
import { DeliveryPoint } from '@models/deliveryPoint';
import { getDataStatus } from '@utils/deliveryPoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDeliveryPointToSelect } from './utils';

export const useMultiDeliveryPointsSelector = () => {
  const deliveryPointsQuery = useDeliveryPoints();

  const [selectedMultiDeliveryPoints, selectMultiDeliveryPoints] = useState<
    DeliveryPoint[]
  >([]);

  useEffect(() => {
    if (!deliveryPointsQuery.data) {
      return;
    }

    // Get the selected delivery points
    const selectedPoints = getDeliveryPointToSelect(deliveryPointsQuery.data);
    selectMultiDeliveryPoints(selectedPoints);
  }, [deliveryPointsQuery.data]);

  // Initialize selectedMultiDeliveryPoints with the first delivery point when the data is available
  useEffect(() => {
    if (deliveryPointsQuery.data && deliveryPointsQuery.data.length > 0) {
      const firstHeatingDeliveryPoint = deliveryPointsQuery.data.find(
        data => data.type === 'heating',
      );
      const firstDeliveryPoint =
        firstHeatingDeliveryPoint ?? deliveryPointsQuery.data[0];
      selectMultiDeliveryPoints([firstDeliveryPoint]);
    }
  }, [deliveryPointsQuery.data]);
  const multiDeliveryPointsStatus: DataStatus = useMemo(
    () => getDataStatus(deliveryPointsQuery),
    [deliveryPointsQuery.status, deliveryPointsQuery.data],
  );

  // Callback to update the selected delivery points
  const onSelectDeliveryPoints = useCallback(
    (deliveryPoints: DeliveryPoint[]) => {
      selectMultiDeliveryPoints(deliveryPoints);
      localStorage.setItem(
        LocalStorageKeys.selectedMultiDeliveryPointIds,
        JSON.stringify(deliveryPoints.map(point => point.id)),
      );
    },
    [],
  );

  return {
    selectedMultiDeliveryPoints,
    selectMultiDeliveryPoints: onSelectDeliveryPoints,
    multiDeliveryPointsStatus,
    multiDeliveryPoints: deliveryPointsQuery.data,
  };
};
