import { CommonFooterProps } from '@fortum/elemental-ui';
import { Language } from '../i18n';

const fiFooterData: CommonFooterProps['data'] = [
  {
    key: '85c9d05e-beb3-4d9a-938a-d790ce2426c0',
    title: 'Kotiasiakkaille',
    absolute: 'https://www.fortum.fi/kotiasiakkaille',
    below: [
      {
        key: '37188835-c59a-46ee-b029-6764f33f1dfd',
        title: 'Kaukolämpö',
        absolute: 'https://www.fortum.fi/kotiasiakkaille/lammitys/kaukolampo',
      },
      {
        key: '130000fe-3848-4c6a-9bde-198ca4d809e4',
        title: 'Sähkösopimukset ja hinnat',
        absolute:
          'https://www.fortum.fi/kotiasiakkaille/sahkoa-kotiin/sahkosopimukset',
      },
      {
        key: '0878cda7-28d6-469a-85cd-3a8e5fb93659',
        title: 'Sähköasiakkaan Oma Fortum',
        absolute: 'https://www.fortum.fi/kotiasiakkaille/oma-fortum-palvelu',
      },
    ],
  },
  {
    key: 'a83de169-f262-4e21-ae08-52e093bbf002',
    title: 'Yrityksille ja taloyhtiöille',
    absolute: 'https://www.fortum.fi/yrityksille-ja-yhteisoille',
    below: [
      {
        key: '7c19cbb5-5071-43d3-96e1-56d4fd41d60d',
        title: 'Kaukolämpö kiinteistöille',
        absolute:
          'https://www.fortum.fi/yrityksille-ja-yhteisoille/lammitys-ja-jaahdytys/kaukolampo',
      },
      {
        key: '7c19cbb5-5071-43d3-96e1-cdd4fd41d60d',
        title: 'Jäähdytysratkaisut',
        absolute:
          'https://www.fortum.fi/yrityksille-ja-yhteisoille/lammitys-ja-jaahdytys/jaahdytys',
      },
      {
        key: 'c519cbb5-5071-43d3-96a1-74d4fd41d60d',
        title: 'Sähkösopimukset',
        absolute:
          'https://www.fortum.fi/yrityksille-ja-yhteisoille/sahkosopimus/yritykselle',
      },
      {
        key: 'c279cgb5-5071-43d3-96a1-74d4fd41d65d',
        title: 'Sähköasiakkaan Yritys Online',
        absolute: 'https://www.fortum.fi/yritysonline',
      },
    ],
  },
  {
    key: 'c95489d3-5877-44d9-b9d7-1856fb1d8ed2',
    title: 'Fortum yrityksenä',
    absolute: 'https://www.fortum.fi/tietoa-meista',
    below: [
      {
        key: '3902aae7-0ee6-4513-b045-66da994bd330',
        title: 'Tämä on Fortum',
        absolute:
          'https://www.fortum.fi/tietoa-meista/yhtiomme/kohti-puhtaampaa-maailmaa',
      },
      {
        key: 'd559f476-0e5a-40b0-aeed-bd7cbc67a774',
        title: 'Sijoittajille',
        absolute:
          'https://www.fortum.fi/tietoa-meista/sijoittajille/ajankohtaista',
      },
      {
        key: '5b82c35c-ac6e-4f9e-a515-2237c25a0b77',
        title: 'Avoimet työpaikat',
        absolute: 'https://www.fortum.fi/tietoa-meista/meille-toihin',
      },
      {
        key: '5b82c35c-ac6e-4f9e-a515-2237c25a0b78',
        title: 'Yhteystiedot',
        absolute:
          'https://www.fortum.fi/tietoa-meista/ota-yhteytta/kaikki-yhteystiedot',
      },
    ],
  },
  {
    key: '06c2e578-8645-48b5-8479-73dda845402a',
    title: 'Asiakaspalvelu',
    absolute:
      'https://www.fortum.fi/kotiasiakkaille/asiakaspalvelu/asiakaspalvelun-yhteystiedot-ja-palautelomake',
    below: [
      {
        key: '249f8c3a-af70-4b6c-b7cd-706d421a8356',
        title: 'Asiakastiedotteet',
        absolute:
          'https://www.fortum.fi/kotiasiakkaille/asiakaspalvelu/asiakastiedotteet',
      },
      {
        key: '07e16036-15c0-41a4-9d2b-9a2f8a570587',
        title: 'Laskutus ja maksaminen',
        absolute:
          'https://www.fortum.fi/kotiasiakkaille/asiakaspalvelu/laskutus-ja-maksaminen',
      },
      {
        key: '0878cda7-2a12-469a-85cd-3a8e5fb93659',
        title: 'Sähköasiakkaan Oma Fortum',
        absolute: 'https://www.fortum.fi/kotiasiakkaille/oma-fortum-palvelu',
      },
      {
        key: 'a123cgb5-5071-43d3-96a1-74d4fd41d65d',
        title: 'Sähköasiakkaan Yritys Online',
        absolute: 'https://www.fortum.fi/yritysonline',
      },
    ],
  },
];

const enFooterData: CommonFooterProps['data'] = [
  {
    key: '85c9d05e-beb3-4d9a-938a-d790ce2426c0',
    title: 'Households',
    absolute: 'https://www.fortum.fi/en',
    below: [
      {
        key: '130000fe-3848-4c6a-9bde-198ca4d809e4',
        title: 'Electricity contracts',
        absolute:
          'https://www.fortum.fi/en/for-households/electricity/contracts',
      },
      {
        key: '0878cda7-28d6-469a-85cd-3a8e5fb93659',
        title: 'Oma Fortum service for electricity customers',
        absolute:
          'https://www.fortum.fi/en/for-households/for-customers/my-fortum-oma-fortum',
      },
    ],
  },
  {
    key: 'a83de169-f262-4e21-ae08-52e093bbf002',
    title: 'Business clients and Housing cooperatives',
    absolute: 'https://www.fortum.fi/en/business-clients',
    below: [
      {
        key: '7c19cbb5-5071-43d3-96e1-56d4fd41d60d',
        title: 'District heating',
        absolute:
          'https://www.fortum.fi/en/business-clients/heating-cooling/district-heating',
      },
      {
        key: 'c279cgb5-5071-43d3-96a1-74d4fd41d65d',
        title: 'Yritys Online service for electricity customers',
        absolute: 'https://www.fortum.fi/en/yritysonline',
      },
    ],
  },
  {
    key: 'c95489d3-5877-44d9-b9d7-1856fb1d8ed2',
    title: 'About us',
    absolute: 'https://www.fortum.fi/en/about-us',
  },
  {
    key: '06c2e578-8645-48b5-8479-73dda845402a',
    title: 'Customer service',
    absolute:
      'https://www.fortum.fi/en/for-households/customer-service/contact-information',
    below: [
      {
        key: '249f8c3a-af70-4b6c-b7cd-706d421a8356',
        title: 'Billing and payments',
        absolute:
          'https://www.fortum.fi/en/for-households/customer-service/invoicing',
      },
    ],
  },
];

const svFooterData: CommonFooterProps['data'] = [
  {
    key: '85c9d05e-beb3-4d9a-938a-d790ce2426c0',
    title: 'Privat',
    absolute: 'https://www.fortum.fi/sv',
    below: [
      {
        key: '37188835-c59a-46ee-b029-6764f33f1dfd',
        title: 'Fjärrvärme',
        absolute: 'https://www.fortum.fi/sv/privat/fjarrvarme',
      },
      {
        key: '130000fe-3848-4c6a-9bde-198ca4d809e4',
        title: 'Elavtal',
        absolute:
          'https://www.fortum.fi/sv/privat/el-till-hemmet/ett-avtal-just-dig',
      },
      {
        key: '0878cda7-28d6-469a-85cd-3a8e5fb93659',
        title: 'Mitt Fortum-tjänsten för elkunder',
        absolute:
          'https://www.fortum.fi/sv/privat/kundservice/mitt-fortum-och-valpas-info',
      },
    ],
  },
  {
    key: 'a83de169-f262-4e21-ae08-52e093bbf002',
    title: 'Företag och husbolag',
    absolute: 'https://www.fortum.fi/sv/foretag',
    below: [
      {
        key: '7c19cbb5-5071-43d3-96e1-56d4fd41d60d',
        title: 'Fjärrvärme',
        absolute:
          'https://www.fortum.fi/sv/foretag/uppvarmning/fjarrvarme-varmer-upp-din-fastighet-i-alla-vader',
      },
      {
        key: 'c519cbb5-5071-43d3-96a1-74d4fd41d60d',
        title: 'Elavtal',
        absolute:
          'https://www.fortum.fi/sv/foretag/elavtal/elavtal-foretag-och-husbolag',
      },
      {
        key: 'c279cgb5-5071-43d3-96a1-74d4fd41d65d',
        title: 'Yritys Online-tjänsten för elkunder',
        absolute: 'https://www.fortum.fi/sv/yritysonline',
      },
    ],
  },
  {
    key: 'c95489d3-5877-44d9-b9d7-1856fb1d8ed2',
    title: 'Om oss',
    absolute: 'https://www.fortum.fi/sv/om-oss',
  },
  {
    key: '06c2e578-8645-48b5-8479-73dda845402a',
    title: 'Kundtjänst',
    absolute: 'https://www.fortum.fi/sv/privat/kundservice/kundtjanst',
    below: [
      {
        key: '07e16036-15c0-41a4-9d2b-9a2f8a570587',
        title: 'Fakturering och betalning',
        absolute:
          'https://www.fortum.fi/sv/privat/kundservice/kundtjanst/fakturering-och-betalning',
      },
    ],
  },
];

export const footerData: { [key in Language]: CommonFooterProps['data'] } = {
  fi: fiFooterData,
  en: enFooterData,
  sv: svFooterData,
};
