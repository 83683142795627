import { appendRequestParams, getCiamAuthorizeRequestParams } from '@api/utils';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { createStateParam } from '@utils/auth';
import { usePkceGenerator } from '@utils/hooks/usePkceGenerator';
import { Urls } from '@api/const';
import { SessionStorageKeys } from '@commons/consts';

import { AppMainLayout } from './AppMainLayout';
import { GeneralError } from './pages';

const App = () => {
  const { pkce, error } = usePkceGenerator();
  const location = useLocation();
  const url = useMemo(() => {
    if (!pkce) return undefined;
    const state = createStateParam(location.pathname);
    const params = getCiamAuthorizeRequestParams(pkce.challenge, state);

    sessionStorage.setItem(SessionStorageKeys.codeVerifier, pkce.verifier);
    sessionStorage.setItem(SessionStorageKeys.state, state);

    return appendRequestParams(Urls.ciamAuthorize, params);
  }, [pkce]);

  if (sessionStorage.getItem(SessionStorageKeys.token)) {
    return <AppMainLayout />;
  }

  if (!url || error) {
    return <GeneralError />;
  }

  window.location.replace(url);
  return null;
};

export default App;
