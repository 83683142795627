import { breakpoints } from '@configs/breakpoints';
import { Box, px2rem, colors, ContentText } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const StyledHeader = styled(Box)`
  height: ${px2rem(80)};
  max-height: ${px2rem(80)};
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-left: ${px2rem(16)};
  padding-right: ${px2rem(40)};
  align-items: center;
  background-color: ${colors.snowWhite};
  border-bottom: 1px solid ${colors.fogGrey};
  box-sizing: border-box;
`;

export const HeaderText = styled(ContentText)`
  font-size: ${px2rem(36)} !important;

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(26)} !important;
  }
`;

export const RightComponentsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const AuthPopover = styled.div`
  display: flex;
  margin: ${px2rem(24)};
  flex-direction: column;
  row-gap: ${px2rem(32)};
  align-items: center;
`;

export const ActionText = styled(ContentText)`
  color: ${colors.cushyBlue};
  :hover {
    color: #2f507f;
  }
  cursor: pointer;
`;

export const ChangeServiceContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${px2rem(16)};
  align-self: flex-start;

  cursor: pointer;
`;

export const RowsDelimiter = styled.div`
  border: 0.5px solid ${colors.fogGrey};
  width: 100%;

  @media screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;
