import { LocalStorageKeys } from '@commons/consts';
import { DeliveryPoint } from '@models/deliveryPoint';

export const getDeliveryPointToSelect = (
  deliveryPoints: DeliveryPoint[],
): DeliveryPoint[] => {
  const selectedDeliveryPointIdsString = localStorage.getItem(
    LocalStorageKeys.selectedMultiDeliveryPointIds,
  );

  if (!selectedDeliveryPointIdsString) {
    return [];
  }

  const selectedDeliveryPointIds = selectedDeliveryPointIdsString.split(',');
  const selectedDeliveryPoints = deliveryPoints.filter(deliveryPoint =>
    selectedDeliveryPointIds.includes(deliveryPoint.id),
  );

  return selectedDeliveryPoints;
};
