import { logException } from '@configs/azureAppInsights/utils';

export const logCiamRedirectErrors = (
  savedState: string | null,
  state: string | null,
  code: string | null,
  codeVerifier: string | null,
) => {
  if (!savedState) {
    logException('Error during CIAM redirect: Saved state not found');
  }
  if (!state) {
    logException(
      'Error during CIAM redirect: State param not found in a redirect',
    );
  }
  if (savedState !== state) {
    logException('Error during CIAM redirect: State mismatch');
  }
  if (!code) {
    logException('Error during CIAM redirect: Authorization code not found');
  }

  if (!codeVerifier) {
    logException('Error during CIAM redirect: Code verifier not found');
  }
};
