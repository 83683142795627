import { logException } from '@configs/azureAppInsights/utils';
import { HttpError } from './types';
import { getAuthorizationHeaders } from './utils';

export const fetchPdf = async (
  url: string,
  fileName: string,
  errorMessage: string,
) => {
  const response = await fetch(url, {
    mode: 'cors',
    headers: getAuthorizationHeaders(),
  });

  if (response.ok) {
    try {
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.target = '_blank';
      alink.click();
      return Promise.resolve();
    } catch (error) {
      logException(`Could not parse PDF. ${error}`);
      return Promise.reject(Error(`Could not parse PDF. ${error}`));
    }
  }

  logException(`An HTTP error occured. ${errorMessage}`);
  return Promise.reject(
    new HttpError(response.status, response.statusText, errorMessage),
  );
};
