import { Namespace } from '@configs/i18n';
import { FortumLogo, Link } from '@fortum/elemental-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ErrorWithBackgroundImage } from './styles';

export const GeneralError: React.FC = () => {
  const { t: commonT } = useTranslation<Namespace>('common');

  return (
    <Container>
      <Link href="https://www.fortum.fi">
        <FortumLogo />
      </Link>

      <ErrorWithBackgroundImage
        header={commonT('errorHandling.generalError.header')}
        description={commonT('errorHandling.generalError.description')}
        displayContactCard
      />
    </Container>
  );
};
