import {
  CIAM_AUTH_MAX_RETRIES,
  SessionStorageKeys,
  LocalStorageKeys,
  TOKEN_REFRESHING_MAX_ATTEMPTS,
} from '@commons/consts';
import { getSubRouteName } from '@routers/utils';
import { v4 } from 'uuid';
import { isFinite } from 'lodash';

export const createStateParam = (path: string) => `${v4()}${path}`;

export const getPathFromState = (state: string) => {
  const startIndex = state.lastIndexOf('/');
  const path = state.substring(startIndex);

  return getSubRouteName(path) ? path : undefined;
};

export const shouldRefreshToken = (): boolean => {
  const refreshAttempts = sessionStorage.getItem(
    SessionStorageKeys.tokenRefreshingAttempts,
  );

  if (refreshAttempts && +refreshAttempts >= TOKEN_REFRESHING_MAX_ATTEMPTS) {
    return false;
  }

  if (refreshAttempts && isFinite(+refreshAttempts)) {
    const increasedAttemptsAmount = +refreshAttempts + 1;

    sessionStorage.setItem(
      SessionStorageKeys.tokenRefreshingAttempts,
      increasedAttemptsAmount.toString(),
    );
  } else {
    sessionStorage.setItem(SessionStorageKeys.tokenRefreshingAttempts, '1');
  }

  return true;
};

export const shouldRetryAuth = (): boolean => {
  const retriesAmount = localStorage.getItem(LocalStorageKeys.ciamAuthRetries);

  if (retriesAmount && +retriesAmount >= CIAM_AUTH_MAX_RETRIES) {
    localStorage.removeItem(LocalStorageKeys.ciamAuthRetries);
    return false;
  }

  if (retriesAmount && isFinite(+retriesAmount)) {
    const increasedRetriesAmount = +retriesAmount + 1;

    localStorage.setItem(
      LocalStorageKeys.ciamAuthRetries,
      increasedRetriesAmount.toString(),
    );
  } else {
    localStorage.setItem(LocalStorageKeys.ciamAuthRetries, '1');
  }

  return true;
};
