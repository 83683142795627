import moment from 'moment';
import {
  DeliveryPoint,
  DeliveryPointType,
  DetailedDeliveryPoint,
  MeteringTask,
  Product,
  RawDeliveryPoint,
  RawDetailedDeliveryPoint,
  RawProduct,
} from './deliveryPoint';

const toDeliveryPointType = (type: number) =>
  type === 0 ? 'heating' : 'cooling';

const toDeliveryPoint = (rawData: RawDeliveryPoint): DeliveryPoint => {
  const { street, postalCode, city, type, contractEndDate, ...rest } = rawData;
  const parsedContractEndDate = moment(contractEndDate);
  return {
    ...rest,
    address: {
      street,
      postalCode,
      city,
    },
    type: toDeliveryPointType(type),
    contractEndDate: parsedContractEndDate.isValid()
      ? parsedContractEndDate
      : null,
  };
};

export const toDeliveryPoints = (
  rawData: RawDeliveryPoint[],
): DeliveryPoint[] => rawData.map(toDeliveryPoint);

export const toDetailedDeliveryPoint = (
  rawData: RawDetailedDeliveryPoint,
): DetailedDeliveryPoint => {
  const {
    name,
    description,
    billingPower,
    billingPowerUnit,
    consumptionTrend,
    billingPowerValidSince,
    contractPower,
    contractPowerUnit,
    contractPowerValidSince,
    reservedPower,
    reservedPowerUnit,
    reservedPowerValidSince,
    products,
    expiredProducts,
    meteringTasks,
    ...basicData
  } = rawData;
  const basicDeliveryPoint = toDeliveryPoint({
    ...basicData,
    contractEndDate: null,
  });

  const mappedProducts: Product[] = products.map(product => {
    const parsedFromDate = moment(product.activationDate);
    const parsedToDate = moment(product.expirationDate);
    return {
      id: product.id,
      name: product.name,
      description: product.description,
      validFrom:
        !!product.activationDate && parsedFromDate.isValid()
          ? parsedFromDate
          : null,
      validTo:
        !!product.expirationDate && parsedToDate.isValid()
          ? parsedToDate
          : null,
      priceCode: product.priceCode,
      type: product.type,
    };
  });

  const mappedExpiredProducts: Product[] = expiredProducts.map(product => {
    const parsedFromDate = moment(product.activationDate);
    const parsedToDate = moment(product.expirationDate);
    return {
      id: product.id,
      name: product.name,
      description: product.description,
      validFrom: parsedFromDate.isValid() ? parsedFromDate : null,
      validTo: parsedToDate.isValid() ? parsedToDate : null,
      priceCode: product.priceCode,
      type: product.type,
    };
  });

  const mappedMeteringTasks: MeteringTask[] = meteringTasks.map(
    meteringTask => {
      const parsedNotificationDate = moment(meteringTask.notificationDate);
      const parsedCompletionDate = moment(meteringTask.completionDate);
      return {
        id: meteringTask.id,
        description: meteringTask.description,
        notificationDate: parsedNotificationDate.isValid()
          ? parsedNotificationDate
          : null,
        completionDate: parsedCompletionDate.isValid()
          ? parsedCompletionDate
          : null,
        status: meteringTask.status,
      };
    },
  );

  const billingPowerValidSinceDate = moment(billingPowerValidSince);
  const contractPowerValidSinceDate = moment(contractPowerValidSince);
  const reservedPowerValidSinceDate = moment(reservedPowerValidSince);

  return {
    ...basicDeliveryPoint,
    name,
    description,
    products: mappedProducts,
    expiredProducts: mappedExpiredProducts,
    meteringTasks: mappedMeteringTasks,
    consumptionTrend,
    billingPower: {
      value: billingPower,
      unit: billingPowerUnit,
      validSinceDate: billingPowerValidSinceDate.isValid()
        ? billingPowerValidSinceDate
        : null,
    },
    contractPower: {
      value: contractPower,
      unit: contractPowerUnit,
      validSinceDate: contractPowerValidSinceDate.isValid()
        ? contractPowerValidSinceDate
        : null,
    },
    reservedPower: {
      value: reservedPower,
      unit: reservedPowerUnit,
      validSinceDate: reservedPowerValidSinceDate.isValid()
        ? reservedPowerValidSinceDate
        : null,
    },
  };
};

export const toRawDeliveryPointType = (type: DeliveryPointType): number =>
  type === 'heating' ? 0 : 1;

export const toRawProducts = (products: Product[]): RawProduct[] => {
  return products.map(product => {
    return {
      id: product.id,
      name: product.name,
      description: product.description,
      activationDate: product.validFrom
        ? product.validFrom.format('yyyy-MM-DD')
        : null,
      expirationDate: product.validTo
        ? product.validTo.format('yyyy-MM-DD')
        : null,
      priceCode: product.priceCode,
      type: product.type,
    };
  });
};
