const popItem = (key: string): string | null => {
  const item = sessionStorage.getItem(key);

  sessionStorage.removeItem(key);

  return item;
};

export const StorageUtils = {
  popItem,
};
