import { ForwardLink } from '@fortum/elemental-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@configs/i18n';
import { URLs } from '@commons/urls';
import { Container, Header, Description, LinksContainer } from './styles';
import { ContactCard } from './ContactCard';

interface ErrorProps {
  header: string;
  description: string;
  displayContactCard?: boolean;
}

export const Error: React.FC<ErrorProps & { className?: string }> = ({
  header,
  description,
  displayContactCard = false,
  className,
}) => {
  const { t: glossaryT } = useTranslation<Namespace>('glossary');

  return (
    <Container className={className}>
      <Header>{header}</Header>
      <Description>{description}</Description>
      <LinksContainer>
        <ForwardLink href={window.location.origin}>
          {glossaryT('app.name')}
        </ForwardLink>
        <ForwardLink href={URLs.fortumFi.url}>{URLs.fortumFi.name}</ForwardLink>
      </LinksContainer>
      {displayContactCard && <ContactCard />}
    </Container>
  );
};
