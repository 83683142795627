import { px2rem, SocialLink } from '@fortum/elemental-ui';

export const footerSocialIcons: SocialLink[] = [
  /* { 
    svg: function TwitterIcon() {
      return (
        <svg viewBox="0 0 27 20" height={px2rem(20)}>
          <path
            d="M23.0470064,3.16175695 C24.1435882,2.53706472 24.9830099,1.54625396 25.3808131,0.369240239 C24.3522188,0.947722476 23.2168134,1.36778931 22.0069961,1.59549771 C21.0382549,0.61233732 19.6602945,0 18.1318464,0 C15.1996016,0 12.8221184,2.26169539 12.8221184,5.0495955 C12.8221184,5.44499648 12.868984,5.83116426 12.9595724,6.20049244 C8.54750151,5.9896676 4.63514579,3.97876363 2.01667893,0.923100598 C1.55893021,1.66778051 1.29853479,2.53552585 1.29853479,3.46179212 C1.29853479,5.21421034 2.2366331,6.76050827 3.65988774,7.66514246 C2.78977695,7.63748681 1.97138473,7.40973444 1.25490446,7.03126099 L1.25490446,7.09435456 C1.25490446,9.54067007 3.08576067,11.5823514 5.51339105,12.0470454 C5.06858354,12.1609216 4.59960371,12.2239712 4.11435494,12.2239712 C3.77150559,12.2239712 3.43997979,12.1916989 3.11483215,12.1301002 C3.79087117,14.1379705 5.75113938,15.5980478 8.07366873,15.6381024 C6.25737137,16.9920419 3.96719501,17.7967376 1.47971158,17.7967376 C1.051173,17.7967376 0.629012583,17.7721157 0.213368984,17.7274886 C2.56339839,19.162988 5.35328993,20 8.35185831,20 C18.1189976,20 23.4578895,12.3039923 23.4578895,5.62966057 L23.4400491,4.97577383 C24.4832022,4.26798276 25.3857123,3.37869328 26.0957219,2.36629441 C25.143111,2.76785086 24.1209411,3.04018642 23.0470064,3.16175695 Z"
            fill="#2AAAFF"
          />
        </svg>
      );
    },
    url: 'https://twitter.com/fortum_oyj',
    alt: 'Fortum Twitter',
  }, */
  {
    svg: function FacebookIcon() {
      return (
        <svg viewBox="0 0 12 20" height={px2rem(20)}>
          <path
            d="M8.08169171,0 C5.14203712,0 3.24209328,1.93189975 3.24209328,4.92202699 L3.24209328,7.191411 L0.611434089,7.191411 C0.384095763,7.191411 0.2,7.37409361 0.2,7.5994299 L0.2,10.8875087 C0.2,11.112845 0.384305679,11.2953195 0.611434089,11.2953195 L3.24209328,11.2953195 L3.24209328,19.5921892 C3.24209328,19.8175255 3.42618904,20 3.65352737,20 L7.08627311,20 C7.31340152,20 7.49749728,19.8173174 7.49749728,19.5921892 L7.49749728,11.2953195 L10.5735968,11.2953195 C10.8011451,11.2953195 10.9852409,11.112845 10.9852409,10.8875087 L10.9865003,7.5994299 C10.9865003,7.49123519 10.9430479,7.38761795 10.8660089,7.31104938 C10.78897,7.23448082 10.6840123,7.191411 10.5748563,7.191411 L7.49749728,7.191411 L7.49749728,5.26762585 C7.49749728,4.34297723 7.72000756,3.87357864 8.93520753,3.87357864 L10.6978667,3.87295444 C10.9249951,3.87295444 11.1090909,3.69027184 11.1090909,3.46514362 L11.1090909,0.411972161 C11.1090909,0.187052006 10.9249951,0.00457746845 10.6982866,0.00416133496 L8.08169171,0 Z"
            fill="#25549E"
          />
        </svg>
      );
    },
    url: 'https://www.facebook.com/FortumSuomi',
    alt: 'Fortum Facebook',
  },
  {
    svg: function LinkedInIcon() {
      return (
        <svg viewBox="0 0 20 20" height={px2rem(20)}>
          <path
            d="M2.390625,0 C3.70890625,0 4.780625,1.0725 4.780625,2.3934375 C4.780625,3.7134375 3.70890625,4.78328125 2.390625,4.78328125 C1.0684375,4.78328125 0,3.7134375 0,2.3934375 C0,1.0725 1.0684375,0 2.390625,0 L2.390625,0 Z M0.3271875,19.8695313 L4.45296875,19.8695313 L4.45296875,6.59734375 L0.3271875,6.59734375 L0.3271875,19.8695313 Z"
            fill="#007BB5"
          />
          <path
            d="M7.03125,6.57765625 L10.9885937,6.57765625 L10.9885937,8.3934375 L11.0453125,8.3934375 C11.5954688,7.34890625 12.9414062,6.25 14.9476563,6.25 C19.1251563,6.25 19.8964063,8.9978125 19.8964063,12.5704688 L19.8964063,19.8498438 L15.7729688,19.8498438 L15.7729688,13.395625 C15.7729688,11.8567188 15.7464062,9.87578125 13.6285938,9.87578125 C11.4826563,9.87578125 11.1548438,11.554375 11.1548438,13.2853125 L11.1548438,19.8498438 L7.03125,19.8498438 L7.03125,6.57765625"
            fill="#007BB5"
          />
        </svg>
      );
    },
    url: 'https://www.linkedin.com/showcase/fortum-heating-cooling',
    alt: 'Fortum LinkedIn',
  },
  {
    svg: function YoutubeIcon() {
      return (
        <svg viewBox="0 0 71 50" height={px2rem(14)}>
          <path
            d="M59.1345263,49.4736842 L11.4766316,49.4736842 C5.13821053,49.4736842 -0.000210526316,44.3357895 -0.000210526316,37.9973684 L-0.000210526316,11.7652632 C-0.000210526316,5.42684211 5.13821053,0.288947368 11.4766316,0.288947368 L59.1345263,0.288947368 C65.4729474,0.288947368 70.6108421,5.42684211 70.6108421,11.7652632 L70.6108421,37.9973684 C70.6108421,44.3357895 65.4729474,49.4736842 59.1345263,49.4736842"
            fill="#FF0000"
          />
          <polygon
            id="Fill-4"
            fill="#FFFFFF"
            points="28.6772105 15.8908421 28.6772105 33.8245263 44.1577368 24.8576842"
          />
        </svg>
      );
    },
    url: 'https://www.youtube.com/user/fortum',
    alt: 'Fortum Youtube',
  },
];
