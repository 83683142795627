import { Namespace } from '@configs/i18n';
import { ContentText, colors } from '@fortum/elemental-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactCardBottomText,
  ContactCardBottomTextContainer,
  Card,
  PhoneNumber,
} from './styles';

export const ContactCard: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { t: glossaryT } = useTranslation<Namespace>('glossary');

  return (
    <Card className={className}>
      <ContentText color={colors.snowWhite} size={29}>
        {glossaryT('contactUs.services.heatingAndCooling.header')}
      </ContentText>
      <PhoneNumber color={colors.snowWhite}>
        {glossaryT('contactUs.services.heatingAndCooling.phoneNumber')}
      </PhoneNumber>
      <ContactCardBottomTextContainer>
        <ContactCardBottomText color={colors.snowWhite}>
          {glossaryT(
            'contactUs.services.heatingAndCooling.callCostDescription',
          )}
        </ContactCardBottomText>
        <ContactCardBottomText color={colors.snowWhite}>
          {glossaryT(
            'contactUs.services.heatingAndCooling.workingHoursDescription',
          )}
        </ContactCardBottomText>
      </ContactCardBottomTextContainer>
    </Card>
  );
};
