import { SessionStorageKeys } from '@commons/consts';
import { AuthConfig } from '@configs/ciam';
import { logException } from '@configs/azureAppInsights/utils';
import getPkce from 'oauth-pkce';
import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePkceGenerator = () => {
  const isMounted = useRef<boolean>(false);
  const [pkce, setPkce] = useState<{ verifier: string; challenge: string }>();
  const [error, setError] = useState<Error | null>(null);
  const location = useLocation();

  useEffect(() => {
    isMounted.current = true;

    if (sessionStorage.getItem(SessionStorageKeys.token)) {
      return undefined;
    }

    getPkce(AuthConfig.codeVerifierLength, (err, { verifier, challenge }) => {
      if (err) {
        logException(`Error during PKCE generation. ${err}`);
        if (isMounted) {
          setError(err);
        }
        return;
      }
      if (isMounted) {
        setPkce({ verifier, challenge });
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, [location.pathname]);

  return { pkce, error };
};
