import { useDeliveryPoints } from '@api/deliveryPoints';
import { LocalStorageKeys } from '@commons/consts';
import { DataStatus } from '@commons/types/dataStatus';
import { DeliveryPoint } from '@models/deliveryPoint';
import { getDataStatus } from '@utils/deliveryPoints';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { getDeliveryPointToSelect } from './utils';

export const useDeliveryPointsSelector = () => {
  const deliveryPointsQuery = useDeliveryPoints();

  const [selectedDeliveryPoint, selectDeliveryPoint] = useState<
    DeliveryPoint | undefined
  >(deliveryPointsQuery.data?.[0]);

  useEffect(() => {
    if (!deliveryPointsQuery.data) {
      return;
    }

    selectDeliveryPoint(getDeliveryPointToSelect(deliveryPointsQuery.data));
  }, [deliveryPointsQuery.data]);

  const deliveryPointsStatus: DataStatus = useMemo(
    () => getDataStatus(deliveryPointsQuery),
    [deliveryPointsQuery.status, deliveryPointsQuery.data],
  );

  const onSelectDeliveryPoint = useCallback(
    (deliveryPoint: DeliveryPoint | undefined) => {
      if (deliveryPoint) {
        localStorage.setItem(
          LocalStorageKeys.selectedDeliveryPointId,
          deliveryPoint.id,
        );
      }

      selectDeliveryPoint(deliveryPoint);
    },
    [],
  );

  return {
    selectedDeliveryPoint,
    selectDeliveryPoint: onSelectDeliveryPoint,
    deliveryPointsStatus,
    deliveryPoints: deliveryPointsQuery.data,
  };
};
