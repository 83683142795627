import { queryKeys } from '@api/queryKeys';
import { HttpError, isUnauthorizedError } from '@api/types';
import { SessionStorageKeys } from '@commons/consts';
import { UserInfo } from '@models/userInfo';
import { useQuery } from '@tanstack/react-query';
import { shouldRefreshToken } from '@utils/auth';
import { usePageRefreshOnUnauthorizedError } from '@utils/hooks';
import { fetchUserInfo } from './userInfo';

export const useUserInfo = () => {
  const { refreshPage } = usePageRefreshOnUnauthorizedError();

  const onError = (error: HttpError | Error) => {
    if (isUnauthorizedError(error) && shouldRefreshToken()) {
      refreshPage(error);
    }
  };

  return useQuery<UserInfo, HttpError | Error>(
    [queryKeys.userInfo],
    fetchUserInfo,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: (failureCount, error) =>
        !isUnauthorizedError(error) && failureCount < 3,
      onError,
      enabled: !!sessionStorage.getItem(SessionStorageKeys.token),
    },
  );
};
