import { Error } from '@components/Error';
import { Box, colors, px2rem } from '@fortum/elemental-ui';
import styled from 'styled-components';
import backgroundImage from '@assets/404.svg';
import { breakpoints } from '@configs/breakpoints';

export const Container = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.snowWhite};
`;

export const ErrorWithBackgroundImage = styled(Error)`
  background-image: url(${backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;

  margin: 0 ${px2rem(24)};

  padding-right: ${px2rem(96)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0 ${px2rem(24)} ${px2rem(64)};
    padding: ${px2rem(120)} 0;
  }
`;
