import { Box, colors, px2rem } from '@fortum/elemental-ui';
import styled from 'styled-components';
import backgroundImage from '@assets/power_outage.svg';
import { Error } from '@components/Error';
import { breakpoints } from '@configs/breakpoints';

export const Container = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.snowWhite};
`;

export const ErrorWithBackgroundImage = styled(Error)`
  background-image: url(${backgroundImage});
  background-position: right top;
  background-repeat: no-repeat;

  padding-top: ${px2rem(40)};
  margin: ${px2rem(64)} ${px2rem(24)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0 ${px2rem(24)} ${px2rem(64)};
    background-size: ${px2rem(205)};
    padding-top: ${px2rem(64)};
  }
`;
