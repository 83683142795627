import { ContentSwitcherItem } from '@fortum/elemental-ui';
import { isNumber } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';

export const useYearSwitcher = (
  yearsAmount: number,
  disabled: boolean = false,
) => {
  const currentYear = useMemo(() => moment().year(), []);

  const [activeTab, setActiveTab] = useState<number>(currentYear);

  const switcherItems = useMemo(() => {
    const items: ContentSwitcherItem<number>[] = [];

    for (
      let year = currentYear - yearsAmount + 1;
      year <= currentYear;
      year += 1
    ) {
      items.push({ id: year, name: year, disabled });
    }
    return items;
  }, [yearsAmount, disabled]);

  const changeActiveTab = useCallback((id: number | string) => {
    if (isNumber(id)) {
      setActiveTab(id);
    }
  }, []);

  return { switcherItems, activeTab, setActiveTab: changeActiveTab };
};
