import { getAuthorizationHeaders, getData } from '@api/utils';
import { HttpError } from '@api/types';
import { Survey, SurveyResponse } from '@models/survey/survey';
import { toSurvey } from '@models/survey/modelMapping';
import { Urls, ContentTypes, RequestHeaderNames } from '@api/const';

export const fetchSurvey = (): Promise<Survey> =>
  getData({
    url: Urls.survey,
    responseMapping: toSurvey,
    errorMessage: 'Error fetching survey questions',
  });

export const submitSurveyResponse = async (surveyResponse: SurveyResponse) => {
  const response = await fetch(Urls.survey, {
    method: 'POST',
    mode: 'cors',
    headers: [
      ...getAuthorizationHeaders(),
      [RequestHeaderNames.contentType, ContentTypes.json],
    ],
    body: JSON.stringify(surveyResponse),
  });

  if (response.ok) {
    return Promise.resolve(surveyResponse);
  }

  return Promise.reject(
    new HttpError(
      response.status,
      response.statusText,
      'Failed to submit the feedback',
    ),
  );
};
