import './index.css';
import '@fortum/elemental-ui/FortumSans.css';
import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { GeneralError } from '@pages/index';
import { reactPlugin } from '@configs/azureAppInsights/appInsights';
import { Loader } from '@fortum/elemental-ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@configs/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import './configs/i18n';
import { Router } from './routers';

const loaderStyle: React.CSSProperties = {
  height: 64,
  width: 64,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const root: HTMLElement | null = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader style={loaderStyle} />}>
      <QueryClientProvider client={queryClient}>
        <AppInsightsErrorBoundary
          onError={GeneralError}
          appInsights={reactPlugin}
        >
          <Router />
        </AppInsightsErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
