import * as React from 'react';
import { FortumLogo, LanguageWidget, Link } from '@fortum/elemental-ui';
import { Language } from '@configs/i18n';
import { UserInfo } from '@models/userInfo';
import { StyledHeader, RightComponentsContainer, HeaderText } from './styles';
import { AuthWidget } from './AuthWidget';

interface AppHeaderProps {
  text: string;
  currentLanguage: Language;
  onLanguageSelect: (language: string) => void;
  languages: Language[];
  userName: string;
  customerType: UserInfo['customerType'];
}

// TODO: adjust to mobile version
export const AppHeader: React.FC<AppHeaderProps> = ({
  text,
  currentLanguage,
  onLanguageSelect,
  languages,
  userName,
  customerType,
}) => (
  <StyledHeader>
    <Link href="https://www.fortum.fi">
      <FortumLogo />
    </Link>
    <HeaderText>{text}</HeaderText>
    <RightComponentsContainer>
      <LanguageWidget
        languages={languages}
        selected={currentLanguage}
        onSelect={onLanguageSelect}
      />
      <AuthWidget userName={userName} customerType={customerType} />
    </RightComponentsContainer>
  </StyledHeader>
);
