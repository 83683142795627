import i18n, { Namespace } from '@configs/i18n';
import { colors } from '@fortum/elemental-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Description,
  Header,
  DescriptionsContainer,
  DetailedDescriptionContainer,
} from './styles';

export const LoginError: React.FC = () => {
  const { t: commonT } = useTranslation<Namespace>('common');
  const detailedDescription = useCallback(
    () => (
      <DetailedDescriptionContainer>
        <Description>
          {commonT('errorHandling.loginError.detailedDescription.phoneLabel')}
        </Description>
        <Description color={colors.oceanGreen}>
          {commonT('errorHandling.loginError.detailedDescription.phoneNumber')}
        </Description>
        <Description>
          {commonT('errorHandling.loginError.detailedDescription.middleText')}
        </Description>
        <Description color={colors.oceanGreen}>
          {commonT('errorHandling.loginError.detailedDescription.contactEmail')}
        </Description>
      </DetailedDescriptionContainer>
    ),
    [i18n.language],
  );
  return (
    <Container>
      <Header>{commonT('errorHandling.loginError.header')}</Header>
      <DescriptionsContainer>
        <Description>
          {commonT('errorHandling.loginError.description')}
        </Description>
        {detailedDescription()}
      </DescriptionsContainer>
    </Container>
  );
};
