import { Message } from 'react-hook-form';
import { HttpStatusCodes } from './const';

export class HttpError extends Error {
  constructor(
    private errorCode: number,
    private errorText: Message,
    message: string,
  ) {
    super(`${message} Status: ${errorCode}, ${errorText}`);
  }

  getErrorCode = () => this.errorCode;

  getErrorText = () => this.errorText;
}

export const isHttpError = (error: Error): error is HttpError =>
  'getErrorCode' in error;

export const isUnauthorizedError = (error: Error) =>
  isHttpError(error) && error.getErrorCode() === HttpStatusCodes.UNAUTHORIZED;
