import { RawSurvey, Survey, RawSurveyQuestion, SurveyQuestion } from './survey';

export const toSurveyQuestion = (
  rawQuestion: RawSurveyQuestion,
): SurveyQuestion => {
  return {
    id: rawQuestion.questionId,
    order: Number(rawQuestion.questionOrder),
    type: rawQuestion.questionType,
    text: rawQuestion.questionText,
  };
};

export const toSurvey = (rawSurvey: RawSurvey): Survey => {
  return {
    id: rawSurvey.portalSurveyId,
    shouldShow: rawSurvey.showSurvey,
    questions: rawSurvey.questions.map(toSurveyQuestion),
  };
};
