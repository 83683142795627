import { HttpError } from '@api/types';
import { DataStatus } from '@commons/types/dataStatus';
import {
  ContractStatus,
  DeliveryPoint,
  DeliveryPointWithUniqueID,
} from '@models/deliveryPoint';
import moment from 'moment';

import { UseQueryResult } from '@tanstack/react-query';

export const getDataStatus = (
  deliveryPointsQuery: UseQueryResult<DeliveryPoint[], HttpError | Error>,
): DataStatus => {
  if (
    deliveryPointsQuery.status === 'success' &&
    deliveryPointsQuery.data.length === 0
  ) {
    return 'empty';
  }
  return deliveryPointsQuery.status;
};

export const addUniqueIds = (
  deliveryPoints: DeliveryPoint[],
): DeliveryPointWithUniqueID[] => {
  return deliveryPoints.map(d => {
    let contractStatus: ContractStatus = 'active';
    if (d.contractEndDate && moment(d.contractEndDate).isBefore(moment())) {
      contractStatus = 'expired';
    }
    return {
      ...d,
      uniqueId: d.id + d.groupId + d.installationId,
      contractStatus,
    };
  });
};

export const addUniqueId = (
  deliveryPoint: DeliveryPoint | undefined,
): DeliveryPointWithUniqueID | undefined => {
  if (deliveryPoint) {
    let contractStatus: ContractStatus = 'active';
    if (
      deliveryPoint.contractEndDate &&
      moment(deliveryPoint.contractEndDate).isBefore(moment())
    ) {
      contractStatus = 'expired';
    }
    return {
      ...deliveryPoint,
      uniqueId:
        deliveryPoint.id + deliveryPoint.groupId + deliveryPoint.installationId,
      contractStatus,
    };
  }
  return undefined;
};

export const addMultiPointsUniqueIds = (
  deliveryPoints: DeliveryPoint[],
): DeliveryPointWithUniqueID[] => {
  return deliveryPoints.map(d => {
    let contractStatus: ContractStatus = 'active';
    if (d.contractEndDate && moment(d.contractEndDate).isBefore(moment())) {
      contractStatus = 'expired';
    }
    const uniqueId = `${d.id}${d.groupId}${d.installationId}`;
    return {
      ...d,
      uniqueId:
        'uniqueId' in d &&
        (d as DeliveryPointWithUniqueID).uniqueId.trim() !== ''
          ? (d as DeliveryPointWithUniqueID).uniqueId
          : uniqueId,

      contractStatus,
    };
  });
};
