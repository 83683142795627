import { queryClient } from '@configs/queryClient';
import { isUserInfo } from '@models/userInfo';
import { queryKeys } from '@api/queryKeys';
import { appInsights } from './appInsights';

export const getInfoToView = (userInfo: unknown) => {
  if (userInfo && isUserInfo(userInfo)) {
    return userInfo.email;
  }
  return 'Not available yet';
};

export const getUserFromCache = () =>
  queryClient.getQueryCache().get(`["${queryKeys.userInfo}"]`)?.state.data;

export const logEvent = (name: string) => {
  const userInfo = getUserFromCache();
  appInsights.trackEvent({ name: `${name} User: ${getInfoToView(userInfo)}` });
};

export const logException = (exception: string) => {
  const userInfo = getUserFromCache();
  appInsights.trackException({
    exception: Error(`${exception} User: ${getInfoToView(userInfo)}`),
  });
};
