import { ContentTypes, RequestHeaderNames, Urls } from '@api/const';
import {
  appendRequestParams,
  getAuthorizationHeaders,
  getCiamEndSessionRequestParams,
  getCiamTokenRequestParams,
  getCiamRevokeTokenRequestBody,
} from '@api/utils';
import { SessionStorageKeys } from '@commons/consts';
import { StorageUtils } from '@utils/storage';

export const fetchTokens = async (authCode: string, codeVerifier: string) => {
  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.urlEncoded);

  const params = getCiamTokenRequestParams(authCode, codeVerifier);

  const urlWithParams = appendRequestParams(Urls.ciamToken, params);

  const response = await fetch(urlWithParams, {
    method: 'POST',
    mode: 'cors',
    headers,
  });

  if (response.ok) {
    return response.json();
  }

  throw Error(`${response.status} ${response.statusText}`);
};

export const endForgerockSession = (token: string) => {
  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.urlEncoded);

  const requestParams = getCiamEndSessionRequestParams(token);
  const urlWithParams = appendRequestParams(Urls.ciamEndSession, requestParams);

  return fetch(urlWithParams, {
    method: 'GET',
    mode: 'cors',
    headers,
  });
};

export const revokeToken = (token: string) => {
  const requestBody = getCiamRevokeTokenRequestBody(token);

  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.json);

  return fetch(Urls.ciamRevokeToken, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(requestBody),
  });
};

export const terminateApplicationSession = async () => {
  await fetch(Urls.appSessionTermination, {
    method: 'GET',
    mode: 'cors',
    headers: getAuthorizationHeaders(),
  });

  const idToken = StorageUtils.popItem(SessionStorageKeys.idToken);
  if (!idToken) {
    throw Error('ID token could not be found in the session storage');
  }

  const token = StorageUtils.popItem(SessionStorageKeys.token);
  if (!token) {
    throw Error('Token could not be found in the session storage');
  }

  await endForgerockSession(idToken);
  await revokeToken(token);
};
