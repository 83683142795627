import { colors } from '@fortum/elemental-ui';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { logException } from '@configs/azureAppInsights/utils';

const A4_FORMAT_WIDTH_MM = 297;
const INK_GREY_RGB = 51;

export const usePdfDownloader = () => {
  const downloadAsPDF = (
    elementToDownload: HTMLElement,
    filename: string,
    title: string,
    imageDimensions: {
      width: number;
      height: number;
    },
  ) => {
    toPng(elementToDownload, {
      cacheBust: true,
      style: { backgroundColor: colors.snowWhite },
      width: imageDimensions.width,
      height: imageDimensions.height,
    })
      .then(dataUrl => {
        const imageScale = imageDimensions.width / imageDimensions.height;
        const imageWidth = A4_FORMAT_WIDTH_MM;
        const imageHeight = imageWidth / imageScale;
        const pdfHeight = imageHeight + 20;

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: 'landscape',
          format: [A4_FORMAT_WIDTH_MM, pdfHeight],
        });

        pdf
          .setFont('Helvetica')
          .setFontSize(12)
          .setTextColor(INK_GREY_RGB)
          .text(title, 10, 10)
          .addImage(dataUrl, 'PNG', 0, 16, imageWidth, imageHeight);

        const pdfBlob = pdf.output('blob');

        const url = URL.createObjectURL(pdfBlob);

        window.open(url);
      })
      .catch(err => {
        logException(`Error while downloading as PDF. ${err}`);
      });
  };

  return { downloadAsPDF };
};
