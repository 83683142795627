import { HttpError, isUnauthorizedError } from '@api/types';
import { SessionStorageKeys } from '@commons/consts';
import { useCallback } from 'react';

export const usePageRefreshOnUnauthorizedError = () => {
  const refreshPage = useCallback((error: HttpError | Error) => {
    if (isUnauthorizedError(error)) {
      sessionStorage.removeItem(SessionStorageKeys.token);
      window.location.reload();
    }
  }, []);

  return { refreshPage };
};
