import { breakpoints } from '@configs/breakpoints';
import { Box, ContentText, px2rem, colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${px2rem(40)};
  align-self: center;
  justify-content: center;
  max-width: ${px2rem(1280)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: ${px2rem(24)};
  }
`;

export const Header = styled(ContentText)`
  font-size: ${px2rem(46)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(29)};
    line-height: 100%;
  }
`;

export const Description = styled(ContentText)`
  white-space: pre-line;
  font-size: ${px2rem(23)};
  line-height: ${px2rem(35)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(20)};
  }
`;

export const LinksContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${px2rem(40)};
`;

export const Card = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.oceanGreen};
  padding: ${px2rem(24)};
  width: fit-content;
  row-gap: ${px2rem(24)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: ${px2rem(16)};
    row-gap: ${px2rem(16)};
  }
`;

export const PhoneNumber = styled(ContentText)`
  font-size: ${px2rem(36)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(23)};
  }
`;

export const ContactCardBottomTextContainer = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
`;

export const ContactCardBottomText = styled(ContentText)`
  font-size: ${px2rem(20)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(18)};
  }
`;
