import { NPS, Float } from '@fortum/elemental-ui';
import { useSurvey } from '@api/feedbackSurvey/useFeedbackSurvey';
import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { submitSurveyResponse } from '@api/feedbackSurvey';
import { Namespace } from '@configs/i18n';
import { useTranslation } from 'react-i18next';
import { Disclaimer, MinScoreLeft, MaxScoreRight } from './styles';

const FeedbackSurvey = () => {
  const { data: surveyData, isLoading, isError, error } = useSurvey();
  const [scoreValue, setScoreValue] = useState(-1);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [shouldDisplaySurvey, setShouldDisplaySurvey] = useState(false);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (surveyData?.shouldShow) {
        setShouldDisplaySurvey(true);
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, [surveyData?.shouldShow]);

  const submitFeedbackHandler = useMutation(submitSurveyResponse, {
    onError: () => setShowErrorMessage('Error while sending the feedback'),
  });
  const { t: glossaryT } = useTranslation<Namespace>('glossary');

  const customNPSLabels = {
    heading: (
      <>
        {glossaryT('feedbackSurvey.NPSLabels.heading')}
        <Disclaimer>
          {glossaryT('feedbackSurvey.NPSLabels.disclaimer')}
        </Disclaimer>
      </>
    ) as unknown as string,
    npsDescription: glossaryT('feedbackSurvey.NPSLabels.npsDescription'),
    feedbackDescription: glossaryT(
      'feedbackSurvey.NPSLabels.feedbackDescription',
    ),
    minScore: (
      <MinScoreLeft>
        {' '}
        {glossaryT('feedbackSurvey.NPSLabels.minScore')}
      </MinScoreLeft>
    ) as unknown as string,
    maxScore: (
      <MaxScoreRight>
        {glossaryT('feedbackSurvey.NPSLabels.maxScore')}
      </MaxScoreRight>
    ) as unknown as string,

    feedbackLabel: glossaryT('feedbackSurvey.NPSLabels.feedbackLabel'),
    thankYou: glossaryT('feedbackSurvey.NPSLabels.thankYou'),
    submit: glossaryT('feedbackSurvey.NPSLabels.submit'),
    close: glossaryT('feedbackSurvey.NPSLabels.close'),
    error: glossaryT('feedbackSurvey.NPSLabels.error'),
  };

  const sendSurveyResponse = (feedbackTextValue: string) => {
    const surveyId = surveyData?.id;
    const ratingQuestionId = surveyData?.questions.find(
      q => q.type === 'rating',
    )?.id;
    const textQuestionId = surveyData?.questions.find(
      q => q.type === 'text',
    )?.id;
    if (!surveyId || !ratingQuestionId || !textQuestionId) {
      throw Error('Required survey parameters are missing');
    }

    submitFeedbackHandler.mutate({
      portalSurveyId: surveyId,
      questionResponses: [
        {
          questionId: ratingQuestionId,
          questionRatingResponse: scoreValue,
          questionTextResponse: null,
        },
        {
          questionId: textQuestionId,
          questionRatingResponse: null,
          questionTextResponse: feedbackTextValue,
        },
      ],
    });
    setIsSurveySubmitted(true);
  };

  const onSubmitScore = (score: number) => setScoreValue(score);
  const onSubmitFeedbackText = (text: string) => sendSurveyResponse(text);
  const handleClose = () => {
    if (!isSurveySubmitted && scoreValue > -1) {
      onSubmitFeedbackText('');
    }
    setShouldDisplaySurvey(false);
  };

  if (shouldDisplaySurvey && isLoading) {
    return <div>Loading survey...</div>;
  }

  if (shouldDisplaySurvey && isError) {
    return <div>Error fetching survey: {error?.message}</div>;
  }

  if (shouldDisplaySurvey && showErrorMessage) {
    return <div>{showErrorMessage}</div>;
  }

  return (
    <Float opened={shouldDisplaySurvey}>
      {shouldDisplaySurvey ? (
        <NPS
          labels={customNPSLabels}
          onSubmitScore={onSubmitScore}
          onSubmitFeedbackText={onSubmitFeedbackText}
          onClose={handleClose}
          maxScore={5}
          minScore={1}
        />
      ) : (
        <div>No survey available.</div>
      )}
    </Float>
  );
};
export { FeedbackSurvey };
