import { ContentTypes, RequestHeaderNames, Urls } from '@api/const';
import { HttpError } from '@api/types';
import { getAuthorizationHeaders, getData } from '@api/utils';
import { Language } from '@configs/i18n';
import {
  toRawUserInfo,
  UserInfo,
  toUserInfo,
  MarketingInfo,
} from '@models/userInfo';

export const fetchUserInfo = async (): Promise<UserInfo> =>
  getData({
    url: Urls.userInfo,
    responseMapping: toUserInfo,
    errorMessage: 'Failed to fetch user info.',
  });

export const updateUserInfo = async (userInfo: UserInfo): Promise<UserInfo> => {
  const rawData = toRawUserInfo(userInfo);

  const response = await fetch(Urls.userInfo, {
    method: 'PATCH',
    mode: 'cors',
    headers: [
      ...getAuthorizationHeaders(),
      [RequestHeaderNames.contentType, ContentTypes.json],
    ],
    body: JSON.stringify(rawData),
  });

  if (response.ok) {
    return Promise.resolve(userInfo);
  }

  return Promise.reject(
    new HttpError(
      response.status,
      response.statusText,
      'Failed to update user info.',
    ),
  );
};

export const fetchMarketingInfo = async (
  language: Language,
): Promise<MarketingInfo> =>
  getData({
    url: `${Urls.marketingInfo}/${language}`,
    errorMessage: 'Failed to fetch marketing info.',
  });
