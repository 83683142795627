import styled from 'styled-components';
import { ContentText, px2rem, Box } from '@fortum/elemental-ui';

export const MinScoreLeft = styled(Box)`
  display: flex;
  white-space: nowrap;
  position: relative;
  right: 95px;
`;

export const MaxScoreRight = styled(Box)`
  display: flex;
  white-space: nowrap;
  position: relative;
  left: 77px;
`;
export const Disclaimer = styled(ContentText)`
  font-size: ${px2rem(16)};
`;
