import { queryKeys } from '@api/queryKeys';
import { HttpError } from '@api/types';
import { DeliveryPoint, DetailedDeliveryPoint } from '@models/deliveryPoint';
import { useQuery } from '@tanstack/react-query';
import { usePageRefreshOnUnauthorizedError } from '@utils/hooks';
import { fetchDeliveryPoint, fetchDeliveryPoints } from './deliveryPoints';

export const useDeliveryPoints = () => {
  const { refreshPage } = usePageRefreshOnUnauthorizedError();

  return useQuery<DeliveryPoint[], HttpError | Error>(
    [queryKeys.deliveryPoints],
    fetchDeliveryPoints,
    {
      onError: refreshPage,
    },
  );
};

export const useDeliveryPoint = (
  deliveryPointId: DeliveryPoint['id'] | undefined,
  groupId: DeliveryPoint['groupId'] | undefined,
  installationId: DeliveryPoint['installationId'] | undefined,
  scope: DeliveryPoint['scope'] | undefined,
) => {
  const { refreshPage } = usePageRefreshOnUnauthorizedError();

  return useQuery<DetailedDeliveryPoint, HttpError | Error>(
    [queryKeys.deliveryPoint, deliveryPointId, groupId, installationId],
    () =>
      fetchDeliveryPoint(deliveryPointId!!, groupId!, installationId!, scope),
    {
      enabled: !!deliveryPointId && !!groupId && !!installationId,
      onError: refreshPage,
    },
  );
};
