import { Urls } from '@api/const';
import {
  appendRequestParams,
  getData,
  getDeliveryPointDetailsRequestParams,
  getEnclosurePdfRequestParams,
} from '@api/utils';
import { fetchPdf } from '@api/pdfFetcher';
import { DeliveryPoint, Enclosure, Certificate } from '@models/deliveryPoint';
import {
  toDeliveryPoints,
  toDetailedDeliveryPoint,
} from '@models/deliveryPoint/modelMapping';

export const fetchDeliveryPoints = (): Promise<DeliveryPoint[]> =>
  getData({
    url: Urls.deliveryPoints,
    responseMapping: toDeliveryPoints,
    errorMessage: 'Could not fetch delivery points',
  });

export const fetchEnclosurePdf = async (
  id: Enclosure['id'],
  installationId: Enclosure['installationId'],
  groupId: Enclosure['groupId'],
  scope: Enclosure['scope'],
  fileName: string,
) => {
  const url = `${Urls.enclosurePDF}/${id}`;
  const requestParams = getEnclosurePdfRequestParams(
    groupId,
    installationId,
    scope,
  );
  const urlWithParams = appendRequestParams(url, requestParams);

  return fetchPdf(
    urlWithParams,
    fileName,
    `Could not fetch the enclosure PDF for the site ${id}`,
  );
};

export const fetchCertificatePdf = async (id: Certificate['id']) => {
  const url = `${Urls.certificatePDF}/${id}`;
  return fetchPdf(
    url,
    id,
    `Could not fetch the certificate PDF for the file ${id}`,
  );
};

export const fetchDeliveryPoint = (
  deliveryPointId: DeliveryPoint['id'],
  groupId: DeliveryPoint['groupId'],
  installationId: DeliveryPoint['installationId'],
  scope: DeliveryPoint['scope'] | undefined,
) => {
  const url = `${Urls.deliveryPoints}/${deliveryPointId}/details`;
  const requestParams = getDeliveryPointDetailsRequestParams(
    groupId,
    installationId,
    scope,
  );

  const urlWithParams = appendRequestParams(url, requestParams);

  return getData({
    url: urlWithParams,
    responseMapping: toDetailedDeliveryPoint,
    errorMessage: 'Could not fetch delivery point details',
  });
};
