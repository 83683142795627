import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { getInfoToView, getUserFromCache } from './utils';

const USER_NAME_ENVELOPE_PARAM = 'userName';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    enableUnhandledPromiseRejectionTracking: true,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

const telemetryInitializer = (envelope: ITelemetryItem) => {
  const userInfo = getUserFromCache();
  if (envelope.data) {
    // eslint-disable-next-line
    envelope.data[USER_NAME_ENVELOPE_PARAM] = getInfoToView(userInfo);
  }
};
appInsights.addTelemetryInitializer(telemetryInitializer);

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
