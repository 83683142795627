import { SortConfig } from '@commons/types/sortConfig';
import { SortOrder } from '@fortum/elemental-ui';
import { useCallback, useMemo, useState } from 'react';

interface UseTableSortingProps<T, TK> {
  getTableKey: (keys: keyof T) => TK;
  getObjectKey: (tableKey: TK) => keyof T;
  defaultConfig: SortConfig<T>;
}

export const useTableSorting = <T, TK>({
  getTableKey,
  getObjectKey,
  defaultConfig,
}: UseTableSortingProps<T, TK>) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>(defaultConfig);
  const sortByTableKeys = useMemo(
    () => sortConfig.sortBy.map(getTableKey),
    [sortConfig.sortBy],
  );

  const onSortByChange = useCallback((newSortBy: TK[]) => {
    const sortByObjectKeys = newSortBy.map(getObjectKey);
    setSortConfig(prev => ({ ...prev, sortBy: sortByObjectKeys }));
  }, []);

  const onSortOrderChange = useCallback((newOrder: SortOrder[]) => {
    setSortConfig(prev => ({ ...prev, sortOrder: newOrder }));
  }, []);

  return {
    sortByTableKeys,
    onSortByChange,
    onSortOrderChange,
    sortConfig,
  };
};
