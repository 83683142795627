import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import Backend from 'i18next-locize-backend';
import { Language } from './types';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import { isSupportedLanguage } from './utils';
// import { resources } from './resources';

export const supportedLanguages = ['en', 'fi', 'sv'] as const;

export const fallbackLng: Language = 'fi';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    supportedLngs: supportedLanguages,
    lng: fallbackLng,
    fallbackLng,
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      referenceLng: fallbackLng,
    },
    // resources: resources(),
  });

moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);

i18n.on('languageChanged', lang => {
  const selectedLanguage: Language = isSupportedLanguage(lang)
    ? lang
    : fallbackLng;
  moment.locale(selectedLanguage);
});

export default i18n;
