import { LocalStorageKeys } from '@commons/consts';
import { DeliveryPoint } from '@models/deliveryPoint';

export const getDeliveryPointToSelect = (
  deliveryPoints: DeliveryPoint[],
): DeliveryPoint | undefined => {
  if (deliveryPoints.length === 0) {
    return undefined;
  }

  let deliveryPointToSelect: DeliveryPoint = deliveryPoints[0];

  const storedDeliveryPointId = localStorage.getItem(
    LocalStorageKeys.selectedDeliveryPointId,
  );

  if (storedDeliveryPointId) {
    const previouslySelectedDeliveryPoint = deliveryPoints.find(
      deliveryPoint => deliveryPoint.id === storedDeliveryPointId,
    );

    if (previouslySelectedDeliveryPoint) {
      deliveryPointToSelect = previouslySelectedDeliveryPoint;
    }
  }

  return deliveryPointToSelect;
};
